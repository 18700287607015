export const aboutStatic = {
  crumbsNav: [
    {
      id: 1,
      link: '/',
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
    },
    {
      id: 2,
      link: '/about/',
      title_ua: 'Про нас',
      title_ru: 'О нас',
      title_en: 'About',
    },
  ],
};
